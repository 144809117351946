'use client';

import { ArrowButtonLink } from '@/components/Links/ArrowButtonLink';
import { ArrowWithPlume } from '@/components/Shapes';
import { Stack } from '@/components/Stacks/Stack';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIsInViewport } from '@/hooks/useIsInViewport';
import { useScreenSize } from '@/hooks/useScreenSize';
import { BREAKPOINTS } from '@/constants/breakpoints';

interface IProps {
  data: {
    title: string;
    subtitle: string;
    description: string;
    list: {
      title: string;
      description: string;
      id: string;
    }[];
    link: {
      text: string;
      to: string;
    };
  };
}

export const JourneyList = ({ data }: IProps) => {
  const increment = useRef<ReturnType<typeof setInterval> | null>(null);
  const [activeStackItem, setActiveStackItem] = useState(0);
  const [isAnimationCalled, setAnimationCall] = useState(false);
  const journeyListRef = useRef(null);
  const screenSize = useScreenSize();

  const isInViewPort = useIsInViewport(journeyListRef);

  const startAnimation = useCallback(() => {
    increment.current = setInterval(() => {
      setActiveStackItem((prevIndex) => {
        const currentIndex = prevIndex + 1;

        if (currentIndex > data.list.length - 1) {
          clearInterval(increment.current!);

          return 0;
        }

        return currentIndex;
      });
    }, 1000);
  }, [data.list.length]);

  const stopAnimation = useCallback(() => {
    if (increment.current) {
      clearInterval(increment.current);
    }

    setAnimationCall(true);
  }, []);

  useEffect(() => {
    if (isInViewPort && screenSize.width >= BREAKPOINTS.sm && !isAnimationCalled) {
      setAnimationCall(true);
      startAnimation();
    }
  }, [data.list.length, isAnimationCalled, isInViewPort, screenSize.width, setAnimationCall, startAnimation]);

  return (
    <section
      data-testid="journey"
      className="flex w-full min-h-[753px] py-[28px] px-base mobile:px-[35px] justify-center items-center"
    >
      <div className="max-w-[1141px] [@media(min-width:1441px)]:max-w-[1367px] w-full flex md-tablet:flex-row flex-col">
        <div className="flex-1 border-white50 md-tablet:border-r-[0.5px] relative lg:py-[30px] z-10">
          <div className="flex flex-col max-w-[584px] sm:pr-[60px] justify-center">
            <h2
              data-testid="journey-title"
              className="text-purple-6 leading-2xl tracking-[3px] text-base font-mono font-medium uppercase"
            >
              {data.title}
            </h2>

            <h3
              data-testid="journey-item-title"
              className="mobile:text-5xl text-4xl text-white mt-xs"
            >
              {data.list[activeStackItem].title}
            </h3>

            <div
              data-testid="journey-item-description"
              className="
                flex justify-center items-center w-full mt-[19px]
                h-[144px]
                [@media(min-width:358px)]:h-[120px]
                mobile:!h-[96px]
                [@media(min-width:569px)]:h-[72px]
                [@media(min-width:768px)]:h-[144px]
                [@media(min-width:950px)]:h-[144px]
                [@media(min-width:1024px)]:!h-[120px]
                [@media(min-width:1106px)]:!h-[96px]
                [@media(min-width:1280px)]:!h-[96px]
                [@media(min-width:1439px)]:!h-[72px]
              "
            >
              <p className="text-base text-white">{data.list[activeStackItem].description}</p>
            </div>

            <ul
              ref={journeyListRef}
              className="flex-col mt-[54px] max-w-[426px] hidden md-tablet:flex"
            >
              {data.list.map((item, index) => (
                <li
                  data-testid="journey-item"
                  key={item.title}
                  className={`
                    text-base font-light font-mono uppercase
                    first:pt-0 last:pb-0
                    py-xs border-b-[0.5px] border-white50
                    tracking-[3px]
                    transition-colors
                    transition-duration-700
                    last:border-b-0
                    ${activeStackItem === index ? 'text-purple-6' : 'text-white hover:text-white/70 cursor-pointer'}
                  `}
                  onClick={() => {
                    stopAnimation();
                    setActiveStackItem(index);
                  }}
                >
                  {item.title}
                </li>
              ))}
            </ul>

            {screenSize.width >= BREAKPOINTS['md-tablet'] && (
              <ArrowButtonLink
                testId="journey-link"
                gradientPosition="h-[350%]"
                buttonClassname="mt-[50px] w-[145.23px] block"
                to={data.link.to + data.list[activeStackItem].id}
              >
                {data.link.text}
              </ArrowButtonLink>
            )}
          </div>

          {screenSize.width >= BREAKPOINTS['md-tablet'] && (
            <ArrowWithPlume
              classname="h-[421.94px] w-[105.05px] bottom-[-300px] right-[150px] opacity-50 block"
              arrowName="arrow-with-plume-dark"
            />
          )}
        </div>

        <div
          className="flex flex-1 justify-center md-tablet:justify-start
          [@media(min-width:951px)]:justify-end items-center md-tablet:pl-[15px]
          [@media(min-width:950px)]:pl-[60px]"
        >
          <Stack
            list={data.list}
            activeIndex={activeStackItem}
          />
        </div>

        <ul className="flex flex-col max-w-[426px] md-tablet:hidden">
          {data.list.map((item, index) => (
            <li
              key={item.title}
              className={`
                    text-base font-light font-mono uppercase
                    first:pt-0 last:pb-0
                    py-xs border-b-[0.5px] border-white50
                    tracking-[3px]
                    transition-colors
                    transition-duration-700
                    last:border-b-0
                    ${activeStackItem === index ? 'text-purple-6' : 'text-white hover:text-white/70 cursor-pointer'}
                  `}
              onClick={() => {
                stopAnimation();
                setActiveStackItem(index);
              }}
            >
              {item.title}
            </li>
          ))}
        </ul>

        {screenSize.width < BREAKPOINTS['md-tablet'] && (
          <ArrowButtonLink
            gradientPosition="h-[350%]"
            buttonClassname="mt-[50px] w-[145.23px] mobile:self-center"
            to={data.link.to + data.list[activeStackItem].id}
          >
            {data.link.text}
          </ArrowButtonLink>
        )}
      </div>
    </section>
  );
};
