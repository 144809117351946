import { StackItemBroder } from '../StackItem/components/StackItemBroder';

interface IProps {
  title: string;
  order: number;
  isActive: boolean;
  rootClassName: string;
  testId?: string;
}

export const StackItem = ({ title, order, isActive, rootClassName, testId }: IProps) => {
  return (
    <div
      data-testid={testId}
      className={`${rootClassName} ${rootClassName}-${order} ${isActive ? 'active' : ''}`}
    >
      <div className={`${rootClassName}-top-side`}>
        <div className={`${rootClassName}-text-wrapper`}>
          <span className={`${rootClassName}-text font-mono`}>{title}</span>
        </div>
      </div>
      <StackItemBroder
        rootClassName={rootClassName}
        order={order}
        key={Math.random()}
      />
      <div className={`${rootClassName}-bottom-side`} />
    </div>
  );
};
