import './index.scss';
import { StackItem } from '../Stack/components/StackItem';

interface IProps {
  list: {
    title: string;
    description: string;
  }[];
  activeIndex: number;
}

export const Stack = ({ list, activeIndex }: IProps) => {
  return (
    <div className="stack-wrapper">
      <div className="stack-list">
        {list.map((item, index) => (
          <div
            data-testid="journey-stack-item"
            key={index}
          >
            <StackItem
              testId="journey-stack-item-side"
              title={item.title}
              order={index}
              isActive={activeIndex === index}
              rootClassName="highlighted-card"
            />
            <StackItem
              title={item.title}
              order={index}
              isActive={activeIndex === index}
              rootClassName="unhighlighted-card"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
