'use client';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import { CaseStudyCard } from '@/components/Layouts/CaseStudyCard';

import 'swiper/scss';
import 'swiper/scss/pagination';
import 'swiper/css/navigation';
import './index.scss';
import { SwiperNavButton } from '../SwiperNavButton';
import { SwiperNavArrowColor, SwiperNavArrowOrientation } from '@/images/svg/SwiperNavArrow';
import { ICardPreview } from '@/interfaces/layout';

interface IProps {
  list: ICardPreview[];
}

export const CaseStudiesSwiper = ({ list }: IProps) => {
  return (
    <div className="w-full flex justify-center">
      <div className="max-w-[1376px] w-full relative">
        <Swiper
          slidesPerView="auto"
          className="case-studies-swiper !overflow-visible"
          grabCursor={true}
          pagination={{
            dynamicBullets: true,
          }}
          navigation={{ nextEl: '.right', prevEl: '.left' }}
          modules={[Pagination, Navigation]}
        >
          {list.map((caseItem) => (
            <SwiperSlide
              key={caseItem.id}
              className="!h-auto !w-full md-tablet:!w-[561px] [&:not(:last-child)]:mr-[22px]"
            >
              <div
                className="
                  rounded-[24px] p-[24px]
                  cursor-pointer h-full
                  bg-white desktop-hover:hover:bg-linear-gradient-light-purple
                "
              >
                <CaseStudyCard
                  tags={caseItem.tags}
                  title={caseItem.title}
                  description={caseItem.description}
                  image={caseItem.image}
                  href={caseItem.href}
                  categories={caseItem.categories}
                  tools={caseItem.tools}
                  titleClassName="font-light"
                  descriptionClassName="italic leading-[30.8px] text-[20px] md-tablet:text-[22px] !mb-0"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <SwiperNavButton
          orientation={SwiperNavArrowOrientation.LEFT}
          color={SwiperNavArrowColor.PINK}
          className="bottom-0 border-purple-13 z-[1] active:bg-white
          hover:disabled:border-purple-13 active:disabled:border-purple-13 left left-[10%]"
        />
        <SwiperNavButton
          orientation={SwiperNavArrowOrientation.RIGHT}
          color={SwiperNavArrowColor.PINK}
          className="bottom-0 border-purple-13 z-[1] active:bg-white
          hover:disabled:border-purple-13 active:disabled:border-purple-13 right right-[10%]"
        />

        <div
          className="absolute bottom-[-850px] right-[-300px]
          h-[1251.6px] w-[982.8px] pointer-events-none bg-contain bg-no-repeat"
          style={{ backgroundImage: `url('/backgrounds/glare-3.svg')` }}
        />
      </div>
    </div>
  );
};
