'use client';
import { IIndustries } from '@/interfaces/layout';
import { useEffect, useRef, useState } from 'react';
import { useIsInViewport } from '@/hooks/useIsInViewport';
import { IndustryItem } from '@/components/Common/IndustryItem';
import { useScreenSize } from '@/hooks/useScreenSize';
import { BREAKPOINTS } from '@/constants/breakpoints';
import Link from 'next/link';

interface IProps extends IIndustries {}

export const Industries = ({ data }: IProps) => {
  const [isAnimationCalled, setAnimationCall] = useState(false);
  const [activeAnimation, setActiveAnimation] = useState(false);
  const listRef = useRef(null);

  const isInViewPort = useIsInViewport(listRef);
  const screenSize = useScreenSize();

  useEffect(() => {
    if (isInViewPort && screenSize.width >= BREAKPOINTS.sm && !isAnimationCalled) {
      setAnimationCall(true);
      setActiveAnimation(true);
    }
  }, [isAnimationCalled, isInViewPort, screenSize.width]);

  return (
    <section
      id="industries"
      className="
        pt-[33px] pb-[40px] relative
        lg:pb-[127px] lg:pt-[128px] lg:px-[149.5px]
        scroll-mt-[96px] w-full
      "
    >
      <div className="container mx-auto">
        <h3
          className="h3-primary-title mb-xs text-left mobile:text-center"
          data-testid="ind-pre-title"
        >
          {data.preTitle}
        </h3>
        <h4
          className="
            h4-secondary-title text-[30px] leading-[42.2px] mb-[30px] text-left mobile:text-center
            md-tablet:text-5xl md-tablet:leading-[67.2px]
            lg:mb-[100px]
          "
          data-testid="ind-title"
        >
          {data.title}
        </h4>
        <ul
          className="grid gap-[16px] mobile:gap-lg grid-cols-2 [@media(min-width:1440px)]:grid-cols-5"
          ref={listRef}
        >
          {data.list.map(({ icon, title, link }, index) => {
            if (link) {
              return (
                <Link
                  key={index}
                  scroll={true}
                  data-testid="ind-list-item"
                  href={`${link}`}
                  role="listitem"
                  prefetch={false}
                >
                  <li>
                    <IndustryItem
                      key={index.toString()}
                      icon={icon}
                      title={title}
                      activeAnimation={activeAnimation}
                    />
                  </li>
                </Link>
              );
            }

            return (
              <div
                key={index}
                data-testid="ind-list-item"
              >
                <li>
                  <IndustryItem
                    key={index}
                    icon={icon}
                    title={title}
                    activeAnimation={activeAnimation}
                  />
                </li>
              </div>
            );
          })}
        </ul>
      </div>
    </section>
  );
};
