'use client';

import React from 'react';
import CountUp from 'react-countup';
import { CycledCountUp } from '@/components/Common/CycledCountUp';

export const Chart = () => {
  return (
    <div className="h-[1024px] w-[1440px] bg-[#0D0D0D] flex aspect-ratio-[2880/2048] scale-[0.86]">
      <div className="w-[280px] h-[1024px] shrink-0 bg-[url(/images/main/nav.png)]"></div>
      <div className="w-full h-full flex px-[32px] pt-[32px]">
        <div className="w-full h-full flex flex-col gap-[32px]">
          <div className="w-full h-[64px] shrink-0 bg-[url(/images/main/header.png)]"></div>
          <div className="w-full h-full flex gap-[32px] pr-xs overflow-hidden">
            <div className="w-[340px] h-full shrink-0 flex flex-col gap-[32px]">
              <div className="w-full h-[402px] shrink-0 bg-[url(/images/main/summary.png)] relative">
                <div
                  className="
                    w-[64px] py-[4px] px-[8px] flex gap-[4px] items-center
                    text-[#FF3D2E] text-xs font-bold leading-sm
                    rounded-[8px] bg-[#FF3D2E]/20
                    absolute top-[256px] left-[249px]
                  "
                >
                  <div>
                    <CountUp
                      delay={5.5}
                      start={0.02}
                      end={0.15}
                      decimals={2}
                      duration={3}
                    />
                    <span>%</span>
                  </div>

                  <div className="h-[5px] w-[10px] bg-[url(/images/main/triangle-red.svg)]"></div>
                </div>
              </div>
              <div className="w-full h-[621px] shrink-0 bg-[url(/images/main/overview.png)] relative">
                <div className="text-white text-[19px] font-semibold leading-[21px] absolute top-[187px] left-[40px]">
                  <CycledCountUp
                    steps={[28891138, 28912331, 28734612]}
                    prefix="$"
                    delay={5000}
                    intervalDuration={12000}
                    duration={6}
                  />
                </div>

                <div className="w-[148px] h-[50px] absolute left-[152px] top-[296px] overflow-hidden">
                  <div
                    className="
                      absolute w-[298px] h-[52px]
                      bg-[url(/images/main/small-chart.svg)] bg-no-repeat small-chart-movement
                    "
                  ></div>
                </div>
              </div>
            </div>
            <div className="w-full h-full flex flex-col gap-[32px]">
              <div className="w-full h-[402px] shrink-0 bg-[url(/images/main/pie-chart.png)] relative">
                <div className="text-white text-[10px] leading-[21px] absolute top-[144.5px] left-[114px]">
                  <CycledCountUp
                    steps={[13489905, 13184321, 13578219]}
                    delay={11000}
                    intervalDuration={12000}
                    duration={6}
                  />
                </div>

                <div className="text-white text-xs font-bold leading-[21px] absolute top-[316.8px] left-[164.5px]">
                  <CountUp
                    delay={5.5}
                    start={16446672}
                    end={18556311}
                    duration={3.5}
                  />
                </div>

                <div className="text-[#FF3D2E] text-xs leading-[21px] absolute top-[354.8px] left-[394.5px]">
                  <CountUp
                    delay={5.5}
                    start={16446672}
                    end={18556311}
                    duration={4}
                  />
                </div>

                <div
                  className="
                    absolute top-[278px] left-[604px] flex gap-[4px] items-center w-[64px] py-[4px] px-[8px]
                    text-[#FF3D2E] text-xs font-bold leading-sm
                    rounded-[8px] bg-[#FF3D2E]/20
                  "
                >
                  <div>
                    <CountUp
                      delay={5.5}
                      start={0.12}
                      end={0.21}
                      decimals={2}
                      duration={2.5}
                    />
                    <span>%</span>
                  </div>

                  <div className="h-[5px] w-[10px] bg-[url(/images/main/triangle-red.svg)]"></div>
                </div>
              </div>
              <div className="w-full h-[402px] shrink-0 bg-[url(/images/main/chart-grid.png)] relative">
                <div
                  className="
                    absolute top-[40px] left-[74px]
                    grid grid-cols-3 grid-rows-2 gap-x-[52px] gap-y-xs
                    leading-[14.52px] text-xs text-[#F5F5F5]
                  "
                >
                  <div className="flex gap-[5px] items-center">
                    <div
                      className="
                        w-[6px] h-[6px] rounded-full bg-[#FBBC04]
                        bounce-yellow-table-point-animation
                      "
                    />
                    <div className="yellow-text-highlight-animation">Avg change: Feb 16 - Feb 17</div>
                  </div>
                  <div className="flex gap-[5px] items-center">
                    <div className="w-[6px] h-[6px] rounded-full bg-[#58CC7E]"></div>
                    <div>Avg change: Feb 18 - Feb 19</div>
                  </div>
                  <div className="flex gap-[5px] items-center">
                    <div className="w-[6px] h-[6px] rounded-full bg-[#FF8C22]"></div>
                    <div>Avg change: Feb 20 - Feb 21</div>
                  </div>
                  <div className="flex gap-[5px] items-center">
                    <div className="w-[6px] h-[6px] rounded-full bg-[#6865E7]"></div>
                    <div>Avg change: Feb 17 - Feb 18</div>
                  </div>
                  <div className="flex gap-[5px] items-center">
                    <div className="w-[6px] h-[6px] rounded-full bg-[#00BCD6] bounce-teal-table-point-animation"></div>
                    <div className="teal-text-highlight-animation">Avg change: Feb 19 - Feb 20</div>
                  </div>
                </div>
                <div className="absolute w-[605px] h-[238px] bottom-[58px] left-[73px]">
                  <div
                    className="
                      absolute top-[76px] left-[200px]
                      w-[6px] h-[6px] rounded-full bg-[#FBBC04]
                      bounce-yellow-chart-point-animation
                    "
                  />
                  <div
                    className="
                      absolute top-[156px] left-[120px]
                      w-[6px] h-[6px] rounded-full bg-[#00BCD6]
                      bounce-teal-chart-point-animation
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
