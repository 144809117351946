import { ISwiperNavArrowProps, SwiperNavArrow } from '@/images/svg/SwiperNavArrow';
import React from 'react';

export interface ISwiperNavButtonProps extends ISwiperNavArrowProps {
  className: string;
}

export const SwiperNavButton = ({ orientation, color, className }: ISwiperNavButtonProps) => (
  <button
    className={`
      flex absolute justify-center items-center 
      rounded-full border w-[44px] h-[44px] 
      hover:border-purple-7 disabled:opacity-50
      active:disabled:bg-transparent
      transition ease-out duration-700
      ${className}
    `}
  >
    <SwiperNavArrow
      orientation={orientation}
      color={color}
    />
  </button>
);
