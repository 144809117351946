'use client';

import { useCountUp } from 'react-countup';
import React, { useCallback, useEffect, useState } from 'react';
import { formatNumberWithCommas } from '@/helpers/formatters';

interface IProps {
  steps: number[];
  delay?: number;
  duration?: number;
  intervalDuration?: number;
  prefix?: string;
}

export const CycledCountUp = ({ steps, delay = 5.5, duration = 9, intervalDuration = 12000, prefix }: IProps) => {
  const [isInitialized, setInitialization] = useState(false);
  const [, setCurrentIndex] = useState(0);
  const countUpRef = React.useRef(null);

  const { update } = useCountUp({
    ref: countUpRef,
    start: steps[0],
    end: steps[1],
    delay: delay,
    duration: duration,
  });

  const updateNumber = useCallback(() => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex < steps.length - 1 ? prevIndex + 1 : 0;
      update(steps[nextIndex]);

      return nextIndex;
    });
  }, [steps, update]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setInitialization(true);
    }, delay);

    return () => clearTimeout(timeout);
  }, [delay]);

  useEffect(() => {
    if (isInitialized) {
      updateNumber();

      const interval = setInterval(() => {
        updateNumber();
      }, intervalDuration);

      return () => {
        clearInterval(interval);
      };
    }
  }, [intervalDuration, isInitialized, updateNumber]);

  return (
    <>
      <div className="flex gap-[3px]">
        {prefix && <span>{prefix}</span>}
        <span ref={countUpRef}>{formatNumberWithCommas(steps[0])}</span>
      </div>
    </>
  );
};
