import Image from 'next/image';
import Link from 'next/link';
import clsx from 'clsx';
import { ICardPreview } from '@/interfaces/layout';

interface IProps extends Partial<ICardPreview> {
  titleClassName?: string;
  descriptionClassName?: string;
  hidden?: string;
  onClick?: () => void;
}

export const CaseStudyCard = ({
  description,
  title,
  hidden,
  image,
  href,
  categories,
  tools,
  titleClassName,
  descriptionClassName,
  onClick,
}: IProps) => {
  return (
    <Link
      data-testid="case-study-card"
      href={`${href}#nav-header`}
      className="block flex flex-col h-full"
      scroll={true}
      onClick={onClick}
    >
      <Image
        data-testid="case-study-card-image"
        src={image?.src || ''}
        alt={image?.alt || ''}
        height={image?.height}
        width={image?.width}
        className="max-h-[220px] mx-auto mobile:rounded-[12px]"
      />
      <div className="flex flex-col mt-[24px]">
        <div className="mb-[16px] mobile:mb-[24px]">
          {categories?.map((category, idx) => {
            return (
              <span
                data-testid="case-study-card-category"
                key={idx.toString()}
                className="
                  uppercase font-mono font-medium
                  leading-[24px] tracking-[1.92px] text-purple-7 ml-[8px] first:ml-0
                "
              >
                {category}
                {idx === categories.length - 1 ? '' : ','}
              </span>
            );
          })}
        </div>
        <h5
          data-testid="case-study-card-title"
          className={clsx(
            `
              text-black-10 font-monoItalic text-2xl 
              transition ease-out duration-1000 mb-[16px]
              group-hover:text-purple-7 lg:min-h-[86px]
              mobile:text-4xl mobile:leading-[43.2px] mobile:mb-[24px] 
            `,
            {
              [`${titleClassName}`]: titleClassName,
            },
          )}
        >
          {hidden ? hidden : title}
        </h5>
      </div>
      {description && (
        <p
          data-testid="case-study-card-description"
          className={clsx(`leading-[25.6px] text-black-9  mb-[16px] mobile:mb-[24px]`, {
            [`${descriptionClassName}`]: descriptionClassName,
          })}
        >
          {description}
        </p>
      )}

      {tools && (
        <div
          data-testid="case-study-card-tools"
          className="flex gap-[8px] flex-wrap mt-[auto]"
        >
          {tools.list.map((tool) => {
            return (
              <span
                data-testid="case-study-card-tool"
                key={tool.title}
                className="
                  px-[10px] py-[2px] text-[14px] inline-block
                  text-center bg-purple-2 text-purple-8 rounded-full
                  mobile:text-[16px]
                "
              >
                {tool.title}
              </span>
            );
          })}
          {tools.more && (
            <span
              data-testid="case-study-card-more"
              className="text-purple-8 py-[2px]"
            >
              {tools.more} more
            </span>
          )}
        </div>
      )}
    </Link>
  );
};
