'use client';

import { ContactFrom } from '@/components/Forms/Contact';
import { ArrowButton } from '@/components/Links/ArrowButton';
import { useEffect, useRef, useState } from 'react';
import { ArrowWithPlume } from '@/components/Shapes';
import { useScreenSize } from '@/hooks/useScreenSize';
import { BREAKPOINTS } from '@/constants/breakpoints';
import { useIsInViewport } from '@/hooks/useIsInViewport';

export const CollapsableBanner = () => {
  const [isOpened, setOpen] = useState(false);
  const [isFormSubmittedSuccessfully, setFormSubmissionStatus] = useState(false);
  const [isAnimationCalled, setAnimationCall] = useState(false);
  const [activeAnimation, setActiveAnimation] = useState(false);

  const collapsableContainer = useRef(null);
  const isInViewPort = useIsInViewport(collapsableContainer);
  const screenSize = useScreenSize();

  useEffect(() => {
    if (isInViewPort && !isAnimationCalled) {
      setAnimationCall(true);
      setActiveAnimation(true);
    }
  }, [isAnimationCalled, isInViewPort]);

  useEffect(() => {
    if (isOpened && collapsableContainer.current) {
      const element = collapsableContainer.current as HTMLElement;

      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 700);
    }
  }, [isOpened, setOpen, collapsableContainer]);

  return (
    <section
      className="
        bg-black-10 relative overflow-hidden
        pt-[118.84px] pb-[95.15px] lg:pt-[182.59px] lg:pb-[124px]
        flex flex-col items-center justify-center
      "
    >
      <div className="flex flex-col items-center justify-center w-full relative max-w-[1376px]">
        {isOpened ? (
          <ArrowWithPlume
            arrowName="arrow-with-plume-pink"
            classname="h-[238.37px] w-[59.27px] top-[-85px] left-[21px] lg:top-[-171px] lg:left-[113px]"
          />
        ) : (
          <ArrowWithPlume
            arrowName="arrow-with-plume-pink"
            classname="
              block
              h-[417.27px] w-[103.75px] bottom-[-315px] right-[66px]
              md-tablet:h-[587.78px]
              md-tablet:w-[264px]
               md-tablet:top-[10px]
              md-tablet:right-[250px]
              md-tablet:bottom-unset
              xl-1:top-[-75px]
              xl-1:!right-[200px]

            "
          />
        )}
        <div className="w-full flex flex-col items-center gap-[15px] text-center px-[15px] relative max-w-[875px]">
          {!isOpened && (
            <ArrowWithPlume
              arrowName="arrow-with-plume-pink"
              classname="
                mobile:h-[238.37px] mobile:w-[59.27px] mobile:top-[-85px] mobile:left-[21px]
                lg:top-[-180px] lg:left-[21px]
                h-[174.7px] w-[43.43px] top-[-101px] left-[57px]
              "
            />
          )}

          <h3
            className="
              text-4xl leading-[43.2px] text-white
              mobile:text-5xl mobile:leading-[67.2px]
              lg:leading-[72px] lg:text-6xl
              relative z-10
            "
          >
            {/* eslint-disable-next-line prettier/prettier,max-len */}
            Succeed <span className="font-monoItalic font-light text-purple-7 italic tracking-[-1.2px]">
              faster
            </span>{' '}
            with Syberry.
          </h3>
          <p className="text-white text-2xl leading-[28.8px] lg:text-4xl lg:leading-[43.2px]">
            Get in touch to discuss your vision—for your software and your business.
          </p>
        </div>
        <div
          ref={collapsableContainer}
          className={`
            max-w-[1207px] relative grid w-full pt-[40px] lg:pt-[81px] 
            duration-1000 transition-[grid-template-rows] 
            ${isOpened ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'}
            scroll-mt-[80px]
          `}
        >
          {isOpened && (
            <ArrowWithPlume
              arrowName="arrow-with-plume-pink"
              classname="
                h-[238.37px] w-[59.27px] lg:h-[391.85px] lg:w-[97.43px]
                lg:top-[-83px] lg:right-[139px] top-[-50px] right-[139px]
              "
            />
          )}

          {isFormSubmittedSuccessfully && screenSize.width >= BREAKPOINTS.lg && (
            <ArrowWithPlume
              arrowName="arrow-with-plume-pink"
              classname="h-[827.61px] w-[205.78px] block left-[-218px] top-[-187px]"
            />
          )}

          <div className="overflow-hidden px-[15px] relative z-10">
            <ContactFrom setFormSubmissionStatus={setFormSubmissionStatus} />
          </div>
        </div>

        <ArrowButton
          testId="open-contact-form-button"
          buttonClassname={`
            text-white  
            w-[133.77px] h-[53.02px] text-[17.67px] leading-[17.67px]
            mobile:w-[232px] mobile:h-[64px] mobile:text-[22px] mobile:leading-[22px] border-white50
            ${isOpened ? 'hidden' : 'flex'}  
          `}
          gradient="bg-conic-gradient-purple"
          gradientPosition={`
            h-[400%]
            ${!activeAnimation ? 'rotate-180' : ''}
          `}
          withArrow={false}
          type="button"
          onClick={() => setOpen(true)}
        >
          Contact us
        </ArrowButton>
      </div>
    </section>
  );
};
