import { ConicBackground } from '@/components/Backgrounds/Conic';
import { IIcon } from '@/interfaces/layout';
import { useScreenSize } from '@/hooks/useScreenSize';
import clsx from 'clsx';
import { BREAKPOINTS } from '@/constants/breakpoints';

interface IProps {
  icon: IIcon;
  title: string;
  testId?: string;
  activeAnimation: boolean;
}

export const IndustryItem = ({ icon, title, activeAnimation }: IProps) => {
  const screenSize = useScreenSize();

  return (
    <div
      className="
          relative rounded-xl overflow-hidden group
          pt-[22px] pb-sm
          sm:pt-[43px] sm:pb-[30px]
          isolate flex flex-col gap-[15.2px] mobile:gap-[23px]
        "
    >
      <div
        className="h-[110px] w-full mobile:h-[150px] bg-contain bg-no-repeat relative z-[3] bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${icon.url})`,
        }}
      />
      <p
        className={clsx(
          `text-[18.08px] leading-[21.7px] mobile:text-[28px] text-white mobile:leading-[42.2px] text-center
           sm:text-[30px] z-[3]`,
          {
            ['transition-transform ease-out group-hover:translate-x-[10px] duration-700']:
              screenSize.width >= BREAKPOINTS.sm,
          },
        )}
      >
        {title}
      </p>
      <div
        className="absolute w-full h-full left-0 top-0 opacity-[10%] z-[2]"
        style={{
          backgroundImage: `url('/backgrounds/noise.svg')`,
        }}
      />
      <div className="absolute w-full h-full left-0 top-0 z-[2] bg-linear-gradient-light-gray" />
      <ConicBackground
        classname={`
              translate-y-[-52.6%] w-[280%] h-[280%] z-[1]
              sm:translate-y-[-53.4%] sm:w-[220%] sm:h-[220%]
              xl:w-[200%] xl:h-[200%] xl:translate-y-[-54.4%]
              ${!activeAnimation ? 'rotate-180' : ''}
            `}
        gradient="bg-conic-gradient-purple-dark-industries"
      />
    </div>
  );
};
