'use client';

import Image from 'next/image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';

interface IProps {
  slides: {
    src: string;
    alt: string;
    width: number;
    height: number;
  }[];
}

export const ClientsSwiper = ({ slides }: IProps) => {
  return (
    <Swiper
      className="swiper-clients"
      slidesPerView={3.5}
      spaceBetween={30}
      initialSlide={0}
      breakpoints={{
        1280: {
          slidesPerView: 6,
        },
        768: {
          slidesPerView: 5,
        },
        496: {
          slidesPerView: 3,
        },
      }}
      loop={true}
      speed={700}
      autoplay={{
        delay: 3000,
      }}
      modules={[Autoplay]}
    >
      {slides.map(({ src, alt, width, height }) => (
        <SwiperSlide
          key={alt}
          className="lient-swiper-slide"
        >
          <div className="flex h-full justify-center items-center">
            <Image
              data-testid="swiper-slide-image"
              src={src}
              alt={alt}
              width={width}
              height={height}
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
